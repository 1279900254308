import { AUTH, CONTENT } from '../Store/Actions'
import store from '../store'
import {Auth } from 'aws-amplify';
import axios from 'axios'

class APFW_API {
    
    static monitorInterval = null

    static CHECK_AUTH() {
        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then((user) => {
            store.dispatch({ type: AUTH.JWT_RECIVED, payload: user.signInUserSession.idToken.jwtToken })
        })
        .catch(() => {
            store.dispatch({ type: AUTH.JWT_RECIVED, payload: null })
        });
    }

    static SIGN_OUT() {
        Auth.signOut({ global: true })
        store.dispatch({ type: AUTH.JWT_RECIVED, payload: null})
        store.dispatch({ type: CONTENT.WIPE_ALL_DATA })
        this.CLEAR_MONITOR_FOR_UPDATES();
    }

    static GET_SAMPLE() {

        let repository = "all-points-fibre-website"
        let branch = store.getState().enviroment
        let filename = "vacancies.json"


        let resource = `sample?repository=${repository}&branch=${branch}&filename=${filename}`;
        this.CALL({ resource:resource } )
        .then(response => {
            store.dispatch({ type: CONTENT.SAMPLE_VACANCY_RECEIVED, payload: response.data.vacancy })
        })
        .catch(error =>{
            console.log(error)
        });

    }

    static PUBLISH_CONTENT() {
        return new Promise((resolve, reject) => {
            let tagData = {
                branch_name:store.getState().enviroment
            };

            let resource = `deploy-tag`;

            this.CALL({resource:resource, data:tagData, method:"POST"})
            .then((result) => {
                resolve(result);
            }).catch(error => {
                if (error.response) {
                    if(error.response.status === 401){
                        this.SIGN_OUT();
                    }
                }
                resolve(error)
            })
        })
    }

    static CLEAR_MONITOR_FOR_UPDATES() {
        if(this.monitorInterval){
            clearInterval(this.monitorInterval);
            this.monitorInterval = null
        }
    }

    static MONITOR_FOR_UPDATES() {
        if(!this.monitorInterval){
            this.monitorInterval = setInterval(() => {
                APFW_API.GET_SAMPLE();
                APFW_API.GET_CONTENT();
            }, 10000);
        }
    }

    static COMMIT_CONTENT() {
        return new Promise((resolve, reject) => {
            let vacanciesData = {
                content: {
                    vacancies: store.getState().vacanciesWorkingContent
                }
                
            };
            vacanciesData.branchName = store.getState().enviroment;
            vacanciesData.repositoryName  = "all-points-fibre-website";
            vacanciesData.repositoryFileName = "vacancies.json";

            let resource = `commit`;

            this.CALL({resource:resource, data:vacanciesData, method:"POST"})
            .then((result) => {
                resolve(result);
            }).catch(error => {
                if (error.response) {
                    if(error.response.status === 401){
                        this.SIGN_OUT();
                    }
                }
                resolve(error)
            })
        })
    }

    static GET_CONTENT() {

        let repository = "all-points-fibre-website"
        let branch = store.getState().enviroment
        let filename = "vacancies.json"

        let resource = `content?repository=${repository}&branch=${branch}&filename=${filename}`;
        this.CALL({ resource:resource } )
        .then(response => {
            if(store.getState().latestVacanciesFromRepositoryHead != null){
                if(JSON.stringify(response.data.vacancies) !== JSON.stringify(store.getState().latestVacanciesFromRepositoryHead)){
                    store.dispatch({ type: CONTENT.VACANCIES_OUT_OF_DATE, payload: true })
                    this.CLEAR_MONITOR_FOR_UPDATES();
                }else{
                    console.log("WE ARE UP TO DATE")
                }
            }else{
                store.dispatch({ type: CONTENT.VACANCIES_RECEIVED, payload: response.data.vacancies })
                this.MONITOR_FOR_UPDATES();
            }
        })
        .catch(error =>{
            console.log(error)
        });

    }

    static CALL({ resource, data = {}, method = 'GET'}){

        return new Promise((resolve, reject) => {
            let headers = {}
            headers.Authorization = store.getState().authData.jwtToken;
            this.CHECK_AUTH()
    
            axios({
                method,
                data,
                headers,
                url: `${store.getState().apiBaseUrl}${resource}`
            }).then(response => {
                resolve(response)
    
            }).catch(error => {
                if (error.response) {
                    if(error.response.status === 401){
                        this.SIGN_OUT();
                    }
                }
                resolve(error)
            });
        })
    }

    static callback;

}
export default APFW_API;