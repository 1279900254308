import { React } from "react";
import { MDBSwitch } from 'mdb-react-ui-kit';
import APFWDatePicker from "./DatePicker";
import TextArea from "./TextArea";

function InputComponent(

    {
        sampleContent, 
        contentKey, 
        content,
        onUpdate,
        className
    }){

    let contentType = sampleContent[contentKey];

    function formatLabel(string){
        let formatted = string.replace(/_/g, " ")
        return formatted
    }

    function getComponentItem(){

        switch (contentType) {
            case "boolean":
                return <MDBSwitch checked={content} onChange={(e) => onUpdate(e.target.checked)} />
            case "textarea":
                return <TextArea onUpdate={(value) => {onUpdate(value)}} content={content}/>
            case "numberinput":
                return <input type={"number"} onChange={(e) => onUpdate(e.target.value.toString())} value={content}></input>
            case "calendar":
                return <APFWDatePicker 
                            selected={content}
                            onChange={
                                (date) => onUpdate(date.getTime().toString())
                            } />
            default:
                return <input onChange={(e) => onUpdate(e.target.value)} value={content}></input>
        }

    }

    return(
        <div className={className}>
            <label>{formatLabel(contentKey)}</label>
            {getComponentItem()}
        </div>
    )

}

export default InputComponent;