export const AUTH = {
    JWT_RECIVED:'authData/jwtReceived'
}
export const CONTENT = {
    SAMPLE_VACANCY_RECEIVED:'content/sampleVacancyReceived',
    VACANCIES_RECEIVED:'content/vacanciesContentReceived',
    VACANCIES_OUT_OF_DATE:'content/vacanciesOutOfDate',
    VACANCIES_WORKING_CONTENT_UPDATED:'content/vacanciesWorkingContentUpdated',
    ADD_NEW_VACANCY:'content/addNewVacancy',
    REMOVE_VACANCY:'content/removeVacancy',
    WIPE_ALL_DATA:"content/wipeAllData"
}