import './Reset.scss';
import {React, useState} from "react";
import {Auth} from 'aws-amplify';
import Loader from '../../../Components/Loader/Loader';
import { Link } from 'react-router-dom';
function Reset() {

    const [challengeName, setChallengeName] = useState("RESET");

    const [username, setUsername] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    async function submitVerificationCode() {
        setChallengeName("LOADING");
        Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
        .then(data => setChallengeName("SUCCESS"))
        .catch(err => {
            setErrorMessage(err.toString())
            setChallengeName("VERIFICATION_CODE_REQUIRED")
        });
    }

    async function resetPassword() {

        setChallengeName("LOADING");
        setErrorMessage("")

        Auth.forgotPassword(username)
        .then(data => setChallengeName("VERIFICATION_CODE_REQUIRED"))
        .catch(err => {
            setErrorMessage(err.toString())
            setChallengeName("RESET")
        });

    }

    switch (challengeName) {
        case "LOADING":
            return (
                <Loader/>
            )
        case "SUCCESS":
            return(
                <>
                <h1>Success</h1>
                <p>Please <Link to={'/'}>Login</Link> using your new password</p>
                </>
            )
        case "VERIFICATION_CODE_REQUIRED":
            return(
                <>
                    <form onSubmit={(e) => {e.preventDefault();submitVerificationCode()}}>
                        <h1>Please check your email for the verification code</h1>
                        <label>
                            <p>Verification Code:</p>
                            <input size={"26"} placeholder={"This has been emailed to you"} value={verificationCode} onChange={(e) => {setVerificationCode(e.target.value)}} type="text" />
                        </label>

                        <label>
                            <p>New Password</p>
                            <input name="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" />
                        </label>

                        <label>
                            <p>Confirm Password</p>
                            <input name="newPasswordConfirm" value={newPasswordConfirm} onChange={(e) => setNewPasswordConfirm(e.target.value)} type="password" />
                        </label>

                        <div>
                            <button disabled={(verificationCode !== "" && newPasswordConfirm === newPassword && newPasswordConfirm !== "" ? false : true)} type="submit">Submit</button>
                        </div>

                        <label>
                            <p>{errorMessage}</p>
                            <p>{(newPassword !== newPasswordConfirm ? "Please ensure that passwords match" : "")}</p>
                        </label>

                    </form>
                </>
            )
        default:
            return(
                <form onSubmit={(e) => {e.preventDefault();resetPassword()}}>
                    <label>
                        <p>Username/Email</p>
                        <input value={username} onChange={(e) => {setUsername(e.target.value)}} type="text" />
                    </label>
                    <div>
                        <button disabled={(username !== "" ? false : true)} type="submit">Submit</button>
                    </div>
                    <label>
                        <p>{errorMessage}</p>
                        <Link to={"/"}>Login</Link>
                    </label>
                </form>
            )
    }
}

export default Reset;