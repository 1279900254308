import { React } from "react";
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalBody, MDBBtn, MDBModalHeader, MDBModalTitle} from 'mdb-react-ui-kit';

function OutOfDateModal({showOutOfDateWarning, onDismiss}) {

    return (
        <>
            <MDBModal show={showOutOfDateWarning} tabIndex='-1' staticBackdrop={true}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Someone else is editing this content!</MDBModalTitle>
                            <MDBBtn 
                                className='btn-close'
                                color='none'
                                onClick={() => {onDismiss(false)}}>
                            </MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>It looks like someone else has saved some changes.</p>
                            <p>You'll need to reload the page to get the latest version.</p>
                            <p><b>Important: </b>When you reload the page,  any unsaved changes you have made <b>will be deleted</b>.  If nessessary make sure you copy them to a safe place before reloading the page.</p>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
    
}

export default OutOfDateModal;