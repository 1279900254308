import { React } from "react";
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalBody, MDBBtn, MDBModalFooter, MDBModalHeader, MDBModalTitle} from 'mdb-react-ui-kit';

function PublishAlertModal({showPublishAlert, onDismiss}) {

    return (
        <>
            <MDBModal show={showPublishAlert} tabIndex='-1' staticBackdrop={true}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>A publish request has been sent</MDBModalTitle>
                            <MDBBtn 
                                className='btn-close'
                                color='none'
                                onClick={() => {onDismiss(false)}}>
                            </MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>You're changes are being processed</p>
                            <p>Please allow up to 5 minutes (usually a minute or two) before you can see them on the live website</p>
                            <p>If you don't see your changes on the live website, please get in touch with the technical team for assistance.</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn 
                                onClick={() => {onDismiss(false)}}>
                                OK
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
    
}

export default PublishAlertModal;