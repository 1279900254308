import React from "react";
import { MDBSpinner } from 'mdb-react-ui-kit';

function Loader({size = 'md'}) {

    return (
        <>
            <MDBSpinner size={size} role='status'>
                <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
        </>
    );
}

export default Loader;