let awsconfig = {
    Auth: {
        
        // REQUIRED - Amazon Cognito Region
        region: 'eu-west-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-west-1_868Tj79tU',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '64nsfm5pk2enuv8nucnrrcslhq'

    }
}

export default awsconfig;