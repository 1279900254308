import {React, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker_override.css"

function APFWDatePicker(
    {
        selected = "",
        onChange
    }
    ){

    function onDatePicked(date){
        setChosenDate(date)
        onChange(date);
    }
    
    const [chosenDate, setChosenDate] = useState(selected === "" ? new Date() : new Date(parseInt(selected)));

    return(
        <DatePicker
            selected={chosenDate}
            dateFormat={"MMM dd yyyy"}
            onChange={
                (date) => onDatePicked(date)
            } />
    );

}

export default APFWDatePicker;