export const initialState = {
    apiBaseUrl:"https://noj2svashi.execute-api.eu-west-1.amazonaws.com/"+process.env.REACT_APP_CONTENT_ENVIROMENT+'/',
    enviroment:process.env.REACT_APP_CONTENT_ENVIROMENT,
    authData: {
        jwtToken:null
    },
    vacancySample: null,
    isOutOfDate:false,
    latestVacanciesFromRepositoryHead:null,
    vacanciesWorkingContent: null
}