import {React} from "react";
import { MDBBtn } from 'mdb-react-ui-kit';
import classes from './ContentMenu.module.scss';
import Loader from "../Loader/Loader"

function ContentMenu({onSave, isSaveBusy, savable, onPublish, isPublishBusy, publishable, message}){

    function onPublishPress(){
        if (window.confirm('Publish this on the live website?')) {
            onPublish()
        }
    }

    return(
        <div className={classes.ContentMenu}>

            <MDBBtn 
                disabled={(isSaveBusy || !savable ? true : false)} 
                onClick={() => {onSave()}} 
                className={classes.ContentMenu__button}>
                    {(!isSaveBusy ? "Save" : <Loader size={"sm"}/>)}
            </MDBBtn>

            <MDBBtn 
                disabled={(isSaveBusy || savable || isPublishBusy || !publishable ? true : false)} 
                className={classes.ContentMenu__button}
                onClick={() => onPublishPress()}>
                    {(!isPublishBusy ? "Publish" : <Loader size={"sm"}/>)}
            </MDBBtn>
            {message}

        </div>
    );
}

export default ContentMenu;