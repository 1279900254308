import {AUTH, CONTENT} from './Store/Actions'
import { initialState } from './Store/Default'
  
// Use the initialState as a default value
export default function appReducer(state = initialState, action) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        case AUTH.JWT_RECIVED: {
            return {
              ...state,
              authData:
                    {
                        jwtToken:action.payload
                    }
            }
        }
        case CONTENT.SAMPLE_VACANCY_RECEIVED: {
            return {
              ...state,
              vacancySample:
                    {
                        ...action.payload
                    }
            }
        }
        case CONTENT.VACANCIES_RECEIVED: {
            return {
                ...state,
                latestVacanciesFromRepositoryHead:
                        [
                            ...action.payload
                        ],
                vacanciesWorkingContent:
                        [
                            ...action.payload
                        ]
            }
        }
        case CONTENT.VACANCIES_OUT_OF_DATE:{
            return {
                ...state,
                isOutOfDate:action.payload
            }
        }
        case CONTENT.VACANCIES_WORKING_CONTENT_UPDATED:{
            return {
                ...state,
                vacanciesWorkingContent:action.payload
            }
        }
        case CONTENT.ADD_NEW_VACANCY:{
            return {
                ...state,
                vacanciesWorkingContent:[
                    ...state.vacanciesWorkingContent,
                    action.payload
                ]
            }
        }
        case CONTENT.REMOVE_VACANCY:{
            return {
                ...state,
                vacanciesWorkingContent:action.payload
            }
        }
        case CONTENT.WIPE_ALL_DATA:{
            return initialState;
        }
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}