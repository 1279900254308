import './Login.scss';
import {React, useState} from "react";
import {Auth} from 'aws-amplify';
import Loader from '../../Components/Loader/Loader';
import { Link } from 'react-router-dom';
import APFW_API from '../../Api/APFWApi'
function Login() {

    const [challengeName, setChallengeName] = useState("LOGIN");

    const [cognitoUser, setCognitoUser] = useState("");

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    function storeLoginSuccess(){
        APFW_API.CHECK_AUTH()
    }

    async function signIn() {
        setErrorMessage("")
        setChallengeName("LOADING")
        try {
            const User = await Auth.signIn(username, password);
            setCognitoUser(User)
            setChallengeName(User.challengeName)
            if(User.challengeName === undefined){
                storeLoginSuccess()
            }
            
        } catch (error) {
            setErrorMessage(error.toString())
            setChallengeName("LOGIN")
        }
    }

    async function resetPassword() {
        setErrorMessage("")
        setChallengeName("LOADING")
        Auth.completeNewPassword(
            cognitoUser,
            newPassword
        ).then(user => {
            storeLoginSuccess()
        }).catch(e => {
          setErrorMessage(e.toString())
          setChallengeName("NEW_PASSWORD_REQUIRED")
        });

    }

    switch (challengeName) {
        case "LOADING":
            return (
                <Loader/>
            )
        case "NEW_PASSWORD_REQUIRED":
            return(
                <>
                    <h1>Please choose your new password before continuing</h1>
                    <form onSubmit={(e) => {e.preventDefault();resetPassword()}}>
                    <label>
                    <p>New Password</p>
                    <input name="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" />
                    </label>
                    <label>
                    <p>Confirm</p>
                    <input name="newPasswordConfirm" value={newPasswordConfirm} onChange={(e) => setNewPasswordConfirm(e.target.value)} type="password" />
                    </label>
                    <div>
                        <button disabled={(newPassword !== newPasswordConfirm ? true : false)} type="submit">Submit</button>
                    </div>
                    <label>
                        <p>{errorMessage}</p>
                        <p>{(newPassword !== newPasswordConfirm ? "Please ensure that passwords match" : "")}</p>
                    </label>
                </form>
                </>
            )
        case "LOGIN":
            return(
                <form onSubmit={(e) => {e.preventDefault();signIn()}}>
                    <label>
                        <p>Username/Email</p>
                        <input value={username} onChange={(e) => {setUsername(e.target.value)}} type="text" />
                    </label>
                    <label>
                        <p>Password</p>
                        <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
                    </label>
                    <div>
                        <button disabled={(username !== "" && password !== "" ? false : true)} type="submit">Submit</button>
                    </div>
                    <label>
                        <p>{errorMessage}</p>
                        <Link to={"/login/reset"}>Reset password</Link>
                    </label>
                </form>
            )
        default:
            return(<Loader/>)
    }
}

export default Login;