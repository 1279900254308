import './App.scss';
import useState from 'react-usestateref';
import { React, useEffect } from "react";
import APFW_API from "./Api/APFWApi"
import { Amplify } from 'aws-amplify';
import Login from './Routes/Login/Login';
import Reset from './Routes/Login/Reset/Reset';
import awsconfig from './Data/awsConfig';
import Loader from './Components/Loader/Loader';
import store from './store'
import { CONTENT } from './Store/Actions'
import OutOfDateModal from './Components/Modal/OutOfDateModal';
import Vacancies from './Routes/Vacancies/Vacancies';
import ContentMenu from './Components/ContentMenu/ContentMenu';
import PublishAlertModal from './Components/Modal/PublishAlertModal';

import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";


function App() {

    Amplify.configure(awsconfig);

    let stateInitials = {
        loggedIn:null,
        vacancySample:null,
        isOutOfDate:false,
        showOutOfDateWarning:false,
        isSaving:false,
        isPublishing:false,
        isPublishable:true,
        hasUncommittedChanges:false,
        showPublishAlert:false,
        vacancyContent:null
    }

    const [loggedIn, setLoggedIn] = useState(stateInitials.loggedIn);
    const [vacancySample, setVacancySample, vacancySampleRef] = useState(stateInitials.vacancySample);
    const [isOutOfDate, setIsOutOfDate, isOutOfDateRef] = useState(stateInitials.isOutOfDate);
    const [showOutOfDateWarning, setShowOutOfDateWarning] = useState(stateInitials.showOutOfDateWarning);
    const [isSaving, setIsSaving] = useState(stateInitials.isSaving)
    const [isPublishing, setIsPublishing] = useState(stateInitials.isPublishing)
    const [isPublishable, setIsPublishable] = useState(stateInitials.isPublishable)
    const [hasUncommittedChanges, setHasUncommittedChanges] = useState(stateInitials.hasUncommittedChanges)
    const [showPublishAlert, setShowPublishAlert] = useState(stateInitials.showPublishAlert)

    //Vacancy content state and ref
    const [vacancyContent, setVacancyContent, vacancyContentRef] = useState(stateInitials.vacancyContent);

    useEffect(() => {

        //Check if we're logged in and dispatch store actions based on the result
        APFW_API.CHECK_AUTH();

    }, [])

    useEffect(() => {
        setShowOutOfDateWarning(isOutOfDate);
        if(isOutOfDate){
            setIsPublishable(false)
        }
    }, [isOutOfDate])

    useEffect(() => {

        if(loggedIn){
            //We've just logged in,  lets grab some data
            //Do we have sample content?
            if(!vacancySample){
                //Lets grab that
                APFW_API.GET_SAMPLE();
            }
            if(!vacancyContent){
                APFW_API.GET_CONTENT();
            }

        }else if(loggedIn === false){
            resetAppStateVariables();
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn])

    store.subscribe(() => {

        //If the JTW token is not set we'll set the state to offline
        if(store.getState().authData.jwtToken !== null){
            setLoggedIn(true)
        }else{
            setLoggedIn(false)
        }

        if(JSON.stringify(vacancySampleRef.current) !== JSON.stringify(store.getState().vacancySample)){
            setVacancySample(store.getState().vacancySample)
        }

        if(vacancyContentRef.current === null){
            setVacancyContent(store.getState().vacanciesWorkingContent)
        }

        if(isOutOfDateRef.current !== store.getState().isOutOfDate){
            setIsOutOfDate(store.getState().isOutOfDate)
        }

        if(JSON.stringify(store.getState().vacanciesWorkingContent) !== JSON.stringify(store.getState().latestVacanciesFromRepositoryHead)){
            setHasUncommittedChanges(true)
        }else{
            setHasUncommittedChanges(false)
        }

    })

    function resetAppStateVariables(){
        setVacancySample(stateInitials.vacancySample);
        setIsOutOfDate(stateInitials.isOutOfDate);
        setShowOutOfDateWarning(stateInitials.showOutOfDateWarning);
        setIsSaving(stateInitials.isSaving)
        setIsPublishing(stateInitials.isPublishing)
        setIsPublishable(stateInitials.isPublishable)
        setHasUncommittedChanges(stateInitials.hasUncommittedChanges)
        setShowPublishAlert(stateInitials.showPublishAlert)
        setVacancyContent(stateInitials.vacancyContent)
    }

    function onVacanciesUpdated(index, value, key){
        //Clone content
        let inputType = vacancySample[key]
        let vacanciesClone = JSON.parse(JSON.stringify(vacancyContentRef.current))
        vacanciesClone[index][key] = value
        store.dispatch({ type: CONTENT.VACANCIES_WORKING_CONTENT_UPDATED, payload: vacanciesClone })
        if(inputType !== "textarea"){
            setVacancyContent(vacanciesClone)
        }

    }

    function onNewVacancy(){
        let vacanciesClone = JSON.parse(JSON.stringify(vacancyContentRef.current))
        let newVacancy = cloneDictonaryWithNoValues(vacancySample);
        vacanciesClone.push(newVacancy)
        store.dispatch({ type: CONTENT.ADD_NEW_VACANCY, payload: newVacancy })
        setVacancyContent(vacanciesClone)
    }

    function onRemoveVacancy(index){
        let vacanciesClone = JSON.parse(JSON.stringify(vacancyContentRef.current))
        vacanciesClone.splice(index, 1);
        store.dispatch({ type: CONTENT.REMOVE_VACANCY, payload: vacanciesClone })
        setVacancyContent(vacanciesClone)
    }

    function publishWorkingContnet(){
        setIsPublishing(true)
        APFW_API.PUBLISH_CONTENT()
        .then(() => {
            setIsPublishing(false)
            setIsPublishable(false)
            setShowPublishAlert(true)
        })
    }

    function saveWorkingContent(){
        setIsSaving(true)
        APFW_API.COMMIT_CONTENT()
        .then((result) => {
            if(result.data.commitId){
                //Successfully updated the repo,  now lets re-synch data
                store.dispatch({ type: CONTENT.VACANCIES_RECEIVED, payload: store.getState().vacanciesWorkingContent })
                setIsPublishable(true)
            }
            setIsSaving(false)
        })
    }

    function cloneDictonaryWithNoValues(objectToClone){
        let clonedObject = JSON.parse(JSON.stringify(objectToClone));
        for (const key in clonedObject) {
            let objectType = typeof clonedObject[key]
            switch (objectType) {
                case "object":
                    if (Object.keys(clonedObject[key]).length > 0){
                        clonedObject[key] = cloneDictonaryWithNoValues(clonedObject[key]);
                    }else{
                        clonedObject[key] = []
                    }
                    break;
            
                default:
                    clonedObject[key] = ""
                    break;
            }
        }
        return clonedObject
    }

    if (loggedIn === false) {
        return (
            <Router>
                <Routes>

                    <Route path="/" element={
                        <div className={"Editor__container"}>
                            <Login setLoggedIn={setLoggedIn} />
                        </div>
                    } />
                    
                    <Route path="/login/reset" element={
                        <div className={"Editor__container"}>
                            <Reset />
                        </div>
                    } />

                </Routes>
            </Router>
        )
    }else if(!vacancySample || !vacancyContent){
        return <>
            <div className={"Editor__container Loading"}>
                <h1>Downloading samples and content</h1>
                <Loader />
            </div>
        </>
    } else {
        return (
            <div className="App">
                <header className="App-header">
                    <Router>
                        <Routes>

                            <Route path="/*" element={
                                <>
                                    <div className="Editor__header">
                                        <button onClick={() => APFW_API.SIGN_OUT()}>Sign Out!</button>
                                    </div>
                                    <div className={(!isSaving ? "Editor__container" : "Editor__container Editor__container--disabled")}>
                                        <Vacancies 
                                            onChange={(index, value, key) => {onVacanciesUpdated(index, value, key)}} 
                                            actualContent={vacancyContent} 
                                            sampleContent={vacancySample}
                                            onNewVacancy={onNewVacancy}
                                            onRemove={(index) => (onRemoveVacancy(index))}/>
                                        <OutOfDateModal showOutOfDateWarning={showOutOfDateWarning} onDismiss={() => {setShowOutOfDateWarning(false)}}/>
                                        <PublishAlertModal showPublishAlert={showPublishAlert} onDismiss={() => {setShowPublishAlert(false)}} />
                                    </div>

                                    <ContentMenu 
                                        isSaveBusy={isSaving} 
                                        onSave={
                                            () => {
                                                saveWorkingContent()
                                            }
                                        }
                                        savable={
                                            (isOutOfDate ? false :hasUncommittedChanges)
                                        }
                                        isPublishBusy={isPublishing}
                                        publishable={isPublishable}
                                        onPublish={
                                            () => {
                                                publishWorkingContnet();
                                            }
                                        }
                                        message={
                                            (isOutOfDate ? "Out of date,  please refresh the page" : "")
                                        }
                                        /> 

                                </>
                            } />

                        </Routes>
                    </Router>
                </header>
            </div>
        );
    }
}

export default App;