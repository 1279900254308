import classes from './Vacancy.module.scss';
import {React, useState, useEffect} from "react";
import InputComponent from '../InputComponent';
import { MDBCollapse, MDBBtn } from 'mdb-react-ui-kit';
function Vacancy({content, sampleContent, onChange, onRemove}) {

    const [isCollapsed, setIsCollapsed] = useState();

    function onVacancyChange(value, key){
        onChange(value, key)
    }

    function onDeletePress(){
        if (window.confirm('Are you sure you want to remove this item?')) {
            onRemove()
        }
    }

    useEffect(() => {
        if(content['title'] === ''){
            setIsCollapsed(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
            <div className={classes.Vacancy}>

                <span>{content['title']}</span>

                <MDBBtn 
                    className={classes.Vacancy__titleItem}
                    tag='a'
                    onClick={() => {
                        setIsCollapsed(!isCollapsed)}}
                    >
                    {(isCollapsed ? "-" : "+")}
                </MDBBtn>

                <InputComponent
                    className={classes.Vacancy__titleItem} 
                    content={content['visible']} 
                    contentKey={'visible'} 
                    sampleContent={sampleContent} 
                    onUpdate={(value) => onVacancyChange(value, 'visible')}/>
                    
                <MDBBtn onClick={() => onDeletePress()}>
                    Delete
                </MDBBtn>

                <MDBCollapse 
                    center
                    className={classes.Vacancy__collapse_container} 
                    show={isCollapsed}>
                    {Object.keys(content).map((key, index) => {
                        switch (key) {
                            case "visible":
                                return ""
                            default:
                                return (
                
                                    <InputComponent 
                                        key={index}
                                        className={classes.Vacancy__input_item} 
                                        content={content[key]} 
                                        contentKey={key} 
                                        sampleContent={sampleContent}
                                        onUpdate={(value) => onVacancyChange(value, key)}/>
                                
                                )
                        }
                    
                    })}
                </MDBCollapse>
            </div>
        </>
    )

}

export default Vacancy;